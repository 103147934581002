export function common() {

  // タブレット表示
  $(function(){
    var ua = navigator.userAgent;
    var viewport = document.querySelector("meta[name=viewport]");
    if((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)){
        viewport.setAttribute("content", "width=device-width,initial-scale=1");
    } else {
        viewport.setAttribute("content", "width=1160");
    }
  });

  // スマホtel link
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    jQuery(function($) {
        $('.tel').each(function() {
            var str = $(this).html();
            if ($(this).children().is('img')) {
                $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
            } else {
                $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
            }
        });
    });
  }

  //正確なvhの取得
  /*-
  使い方: calc(var(--vh, 1vh) * 100);
  -*/
  function setHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setHeight();//初期化
  window.addEventListener('resize', setHeight);// 再計算


  //ブレイクポイント画像切り替え
  var $elem = $('.sp_img');
  var sp = '_sp.';
  var pc = '_pc.';
  var replaceWidth = 767; //ブレイクポイント指定
  function imageSwitch() {
    var windowWidth = parseInt($(window).width()); //ウィンドウサイズ取得
    $elem.each(function () {
        var $this = $(this);
        if (windowWidth >= replaceWidth) {
            $this.attr('src', $this.attr('src').replace(sp, pc));
        } else {
            $this.attr('src', $this.attr('src').replace(pc, sp));
        }
    });
  }
  imageSwitch();
  // リサイズの実行タイミング設定
  var delayStart;
  var delayTime = 200;
  $(window).on('resize', function () {
  clearTimeout(delayStart);
    delayStart = setTimeout(function () {
      imageSwitch();
    }, delayTime);
  });


  //ヘッダーの高さに連動
  function headerHeightSwitch() {
    var headerHeight = $('.l-header').outerHeight(); //ウィンドウサイズ取得
    //$('.l-main_inner').css({'padding-top': headerHeight});
    $('.anchor').css({'padding-top': headerHeight});
    $('.anchor').css({'margin-top': -headerHeight});
  }
  headerHeightSwitch();
  $(window).on('resize', function () {
    headerHeightSwitch();
  });


  //scroll_header
  var startPos = 0,winScrollTop = 0;
  var headerHight = $('.l-header_inner').outerHeight();
  jQuery(window).on('scroll',function(){
      winScrollTop = $(this).scrollTop();
      if (jQuery(this).scrollTop() > headerHight) {
        if (winScrollTop >= startPos) {
          jQuery('.scroll_header').addClass('hide').css({top: -headerHight});
        } else {
          jQuery('.scroll_header').removeClass('hide').css({top: 0});
        }
        startPos = winScrollTop;
    }
  });


  //js-scroll
  var wH = $(window).height();
  var EffectH = wH/5*1;
  jQuery(window).on('scroll load', function() {
    var scTop = $(this).scrollTop();
    var scBottom = scTop + $(this).height();
    var effectPos = scBottom - EffectH;
    jQuery('.js-scroll, .js-scroll-delay').each( function() {
      var thisPos = $(this).offset().top;
      if ( thisPos < effectPos ) {
        $.when(
          jQuery(this).addClass("show")
        ).done(function() {
          jQuery(this).delay(250).queue(function(){
              jQuery(this).addClass("done")
          })
        });
      }
    });
  });

  //KVのimgSwichの最初のスピードを変更
  $(function(){
    setTimeout(function(){
      $('.p-indexHero_imgSwich--01').removeClass(" js-firstSpeed");
    },4000);
  });


  //ページトップ
  var pageTop = $('#pageTop');
  pageTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      pageTop.fadeIn();
    } else {
      pageTop.fadeOut();
    }
  });
  pageTop.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 550);
    return false;
  });

  //スムーススクロール
  $('a[href^="#"]').click(function () {
    var speed = 550;
    var href = $(this).attr("href");
    //var headerHight = $('.l-header_inner').outerHeight();
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });




  //横スライド
  if (window.matchMedia('(min-width: 768px)').matches) {

    window.addEventListener("load", function(){

      //プラグインを定義
      gsap.registerPlugin(ScrollTrigger);
      
      const area  = document.querySelector(".js-area");
      const wrap  = document.querySelector(".js-wrap");
      const items = document.querySelectorAll(".js-item");
      const num   = items.length;
      
      gsap.set(wrap,  { width: num * 100 + "%" });//横幅を指定
      gsap.set(items, { width: (660 / 1512 * 100) / num + "%" });//横幅を指定
      
      gsap.to(items, {
        xPercent: -100 * ( num - 1 ), //x方向に移動させる
        ease: "none",
        scrollTrigger: {
          trigger: area, //トリガー
          start: "top top", //開始位置
          end: "+=1000", //終了位置 スクロール量の幅調整
          pin: true, //ピン留め
          scrub: true, //スクロール量に応じて動かす
        }
      });
    });

  }else{

    window.addEventListener("load", function(){

      //プラグインを定義
      gsap.registerPlugin(ScrollTrigger);
      
      const area  = document.querySelector(".js-area");
      const wrap  = document.querySelector(".js-wrap");
      const items = document.querySelectorAll(".js-item");
      const num   = items.length;
      
      gsap.set(wrap,  { width: num * 100 + "%" });//横幅を指定
      gsap.set(items, { width: 100 / num + "%" });//横幅を指定
      
      gsap.to(items, {
        xPercent: -100 * ( num - 1 ), //x方向に移動させる
        ease: "none",
        scrollTrigger: {
          trigger: area, //トリガー
          start: "top top", //開始位置
          end: "+=1000", //終了位置 スクロール量の幅調整
          pin: true, //ピン留め
          scrub: true, //スクロール量に応じて動かす
        }
      });
    });

  }
}
